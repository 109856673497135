<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>User Groups</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                    
              </CCol>              
              <CCol md="2"> 
                   <b-button variant="primary" to="/ugroups/create" >New Group</b-button>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>       
          <div class="table-responsive table">
            <b-table striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="name" sort-asc="true">

              <template v-slot:cell(createDate)="{ item }"> 
                  {{ item.createDate | dateWithTime }}
              </template>

              <template v-slot:cell(actions)="{ item }">
                <b-dropdown variant="info" text="Actions" size="md">
                  <router-link :to='"/ugroups/detail/" + item.groupUniqueNumber' tag="b-dropdown-item" > Detail </router-link>
                  <!--router-link :to='"/ugroups/edit/" + item.groupUniqueNumber' tag="b-dropdown-item" > Edit </router-link-->
                </b-dropdown>
              </template>

            </b-table>
          </div>

          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>       
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>      
          </div>          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import TransactService from '@/api/TransactService.js';

 export default {
    name: "usergroups",
    data() {
      return {
        items:[],             
        fields: [   
          {
            key: 'groupUniqueNumber',
            label : 'Unique Number',
            sortable: true
          },  
          {
            key: 'name',
            label : 'Group Name',
            sortable: true
          },                     
          {
            key: 'version',
            label : 'Version',
            sortable: true
          },
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },            
          {            
            key: 'actions',
            label: 'Actions'         
          } 
          ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

    },

    created () { 
       this.getUserGroupList();      
    },
    methods: {      

      getUserGroupList: function() {
        TransactService.getUserGroupList().then(resp => {  //console.log(resp);     
            this.items = resp;  
        }, error => {
          this.loading = false;
        });
      }


    }    
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>